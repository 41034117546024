// colors
$goldColor: #c7ae3f;
$silverColor: silver;
$bronzeColor: #b37e3a;

// $blueColor: #ceade6; // halloween theme turns purple
// $redColor: #ec9729; // halloween theme turns orange
$greyColor: lightgrey;
$greenColor: lightgreen;
$maintenanceColor: orange;
$white: white;
$background: #ddd;
// breakpoints
$breakpoint-phone: 576px;

@import '../../node_modules/c3/c3.min.css';

// fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

@import './app';
@import './amigocard';
@import './dark';

