$lighterDM: #c9c9c9;
$greyColorDM: #999;
$medDarkDM: #2a2a2a;
$darkDM: #1a1919;
$blueColorDM: #68a4b7;
$redColorDM: #b35050;

.dark-mode-toggle-container {
  // width: 100%;
  height: 100%;
  font-size: 1.4em;
  text-align: center;
  line-height: 44px;
  vertical-align: middle;
}

.theme-dark {
  body {
    background-color: $darkDM;
    color: $lighterDM;


  }
}