body {
  font-family: "MyFont", Arial, serif !important;
  overflow-x: hidden;
  background: $background;
  margin: 0;
}

.header {
  background: #ccc;
  padding: 0 20px;
}

.title-head {
  &.xs {
    text-align: center;
  }
}

.links-container {
  &.xs {
    text-align: center;
    font-size: .9em;
  }
  &.sm {
    font-size: .9em;
  }
  &:not(.xs) {
    text-align: end;
  }
}
.header-link {
  display: inline-block;
  padding: 4px 12px;
  margin: 17px 0px;
  
  a {
    display: inline-block;
    color: #333;
    text-decoration: none;
    transition: 100ms;

    &.current {
      font-weight: bold;
    }
    &:not(.current) {
      opacity: .8;
      &:hover {
        opacity:1;
      }
    }
  }
}

button {
  border: none;
  background: none;
  outline: none;
}

.centered {
  text-align: center;
}

.amigo-head {
  text-align: center;
  &.w-below {
    padding: 5px;
  }
  button {
    padding: 0;
    box-sizing: content-box;
  }
  img {
    max-height: 200px;
    max-width: 200px;
    max-width: 100%;
    border-radius: 50%;
    box-sizing: border-box;

    &.border-pos-0 {
      border: $goldColor solid 6px;
    }
    &.border-pos-1 {
      border: $silverColor solid 6px;
    }
    &.border-pos-2 {
      border: $bronzeColor solid 6px;
    }
  }
  .no-pic-circle {
    max-height: 200px;
    max-width: 200px;
    max-width: 100%;
    background: lightgrey;
    border-radius: 50%;
    padding: 40px;

    &.a {
      background: forestgreen;
    }

    &.m {
      background: blueviolet;
    }

    &.s {
      background: orangered;
    }
  }

  .text-below {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 2px;
    font-weight: bold;

    &.pos-0 {
      background: $goldColor;
    }
    &.pos-1 {
      background: $silverColor;
    }
    &.pos-2 {
      background: $bronzeColor;
    }
  }
}

.event-stack {
  position: relative;
  padding-bottom: 80px;
  
  .trophy-container {
    position: absolute;
    left: 65%;
    bottom: 0px;
    margin-bottom: 12%;
    font-size: 40px;
    background: $background;
    border-radius: 50%;
    
    &.xs {
      display: none;
    }

    svg {
      padding: 10px;
    }
    
    .trophy {
      width: 50px;
      height: 50px;
      font-size: 30px;
      border-radius: 50% 50%;
      
      &.first {
        border: 3px solid $goldColor;
        color: $goldColor;
        fill: $goldColor;
      } 
      &.second {
        border: 3px solid $silverColor;
        color: $silverColor;
        fill: $silverColor;
        
      } 
      &.third {
        border: 3px solid $bronzeColor;
        color: $bronzeColor;
        fill: $bronzeColor;
      } 
    }
  }

  .amigo-head {
    padding: 12px;
    img {
      max-width: 100%;
    }
  }
}

.event-block {
  position: relative;
  background: rgba(white, 0.3);
  border-left: solid 3px transparent;
  margin-bottom: 2px;
  padding: 2px;
  display: table;
  width: calc(100% - 3px);
  
  &.earned {
    background: rgba(white, 0.5);
    &.mm {
      width: calc(100% - 3px);
      border-left: solid 3px darken(royalblue, 4%);
    }
    &.pp {
      width: calc(100% - 3px);
      border-left: solid 3px darken( orange, 4%); 
    }
    &.blc {
      width: calc(100% - 3px);
      border-left: solid 3px darken(#ff0095, 4%); 
    }

    &.in-first {
      border-right: $goldColor solid 2px;
    }
    &.in-second {
      border-right: $silverColor solid 2px;
    }
    &.in-third {
      border-right: $bronzeColor solid 2px;
    }
  }

  &:hover, &.force-hover {
    background: rgba(white, 0.8); 
    &:not(.mm):not(.pp):not(.blc) {
      border-left: solid 3px darken(white, 1%);
    }
  }

  &.mm {
    .event-flag-container { 
      position: absolute; 
      left: 0px;
      height: 0px; 
      top: 4px;
      min-width: 40px;
      margin-left: -3px;
      border-bottom: 18px solid darken(royalblue, 2%);
      border-top: 18px solid royalblue; 
      border-right: 18px solid transparent;
      
      h5 {
        color: white;
        padding: 0px 12px;
        font-size: 1em;
        margin-top: -7px;
      }  
    }
  }

  &.pp {
    .event-flag-container { 
      position: absolute; 
      left: 0px;
      top: 4px;
      height: 0px; 
      min-width: 30px;
      margin-left: -3px;
      border-bottom: 8px solid darken( orange, 2%); 
      border-top: 8px solid orange; 
      border-right: 8px solid transparent;
      
      h5 {
        font-size: .7em;
        margin-top: -7px;
      }  
    }
  }

  &.blc {
    .event-flag-container { 
      position: absolute; 
      left: 0px;
      top: 4px;
      height: 0px; 
      min-width: 40px;
      margin-left: -3px;
      border-bottom: 14px solid darken(#ff0095, 2%); 
      border-top: 14px solid #ff0095; 
      border-right: 14px solid transparent;
      
      h5 {
        padding: 0px 12px;
        font-size: .9em;
        margin-top: -7px;
      }  
    }
  }

  .event-points {
    display: table-cell;
    vertical-align: middle;

    h4, h5 {
      margin: 0;
    }

    &:hover {
      .running-total {
        opacity: 1;
      }
    }

    .curr-points {
      font-size: .6em;
    }
    .running-total {
      font-size: .6em;
      opacity: .4;
      font-style: italic;

    }
  }
}

.stack-total {
  width: 100%;
  text-align: center;
}

.chart-toggle {
  margin : 8px;
  padding: 4px 12px;
  text-align: center;
}

.colored-stat-block {
  border-radius: 6px;
  background-color: rgba(lightblue, .6);
  border: 2px solid rgba(lightblue, .6);
  
  &:nth-of-type(n+4) {
    background-color: rgba(lightgreen, .6);
    border: 3px solid rgba(lightgreen, .6);
  }
  &:nth-of-type(2n+2) {
    background-color: rgba(pink, .6);
    border: 3px solid rgba(pink, .6);
  }
  &:nth-of-type(3n+5) {
    background-color: rgba(orange, .6);
    border: 3px solid rgba(orange, .6);
  }
  &:nth-of-type(7n+2) {
    background-color: rgba(yellow, .6);
    border: 3px solid rgba(yellow, .6);
  }
  &:nth-of-type(5n+1) {
    background-color: rgba(purple, .6);
    border: 3px solid rgba(purple, .6);
  }
  &:nth-of-type(8n+3) {
    background-color: rgba(lightblue, .6);
    border: 3px solid rgba(lightblue, .6);
  }
  &:nth-of-type(9n+7) {
    background-color: rgba(rgb(247, 41, 86) , .6);
    border: 3px solid rgba(rgb(247, 41, 86) , .6);
  }
}

.stat-block {
  padding: 16px;
  font-size: 1.1em;

  .amigo-head {
    img {
      min-height: 200px;
      padding: 20px;
    }
  }

  .stat-value {
    font-size: 1.5em;
    font-weight: bold;
    color: black;

    &.numeric {
      margin-top: 35%;
    }
  }

  h2, h3, h4 {
    margin: 0;
    margin-bottom: 2px;
  }

  .non-leading-heads {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    
    .non-leader-text {
      display: inline-block;
      padding: 4px; 
      font-weight: 800;
      font-size: .7em;
      color: black;
      opacity: .2;
    }

    .amigo-head {
      display: inline-block;
      position: relative;
      width: 80px;
      img {
        min-height: 80px;
        padding: 20px;
      }
    }
  }
}

.cheer-count {
  position: absolute;
  margin-top: 8px;
  margin-left: -8px;
  font-size: 1.2em;
  width: calc(100% - 16px);
  text-align: center;
  transform: 200ms;
  color: rgba(0,0,0,.7);

  &.xs, &.sm {
    margin-left: 4px;
  }

  &.invisible {
    color: rgba(0,0,0,0);
    // margin-top: -40px;
  }
  &:hover {
    color: rgba(0,0,0,.9);
  }
}

.recent-standings {
  margin-top: 60px;
  // margin-bottom: -60px;
}

.recent-standings-date {
  margin: 8px 4px 4px;
}

.inline-container {
  position: relative;
}


.events-page {
  .event-row {
    height: 100%;
    background: rgba(white, 0.4);
    border-radius: 12px;
    margin-bottom: 12px;
    padding: 12px;
    min-height: 100px;
    &.xs {
      padding-bottom: 22px;
    }

    .event-icons-container {
      display: flex;
      flex-direction: row-reverse;
      margin-top: -10px;
      margin-right: 50px;

      .category-icon {
        display: block;
        position: relative;

        &:after {
          position: absolute;
          display: -webkit-flex;
          top: 26px;
          left: 4px;
          width: 0px;
          height: 0px;
          border-top: 8px solid #d6dade;
          border-left: 16px solid transparent;
          border-right: 16px solid transparent;
          clear: both;
          content: '';

        }
        &.pp {
          margin-right: -55px;
          &:after {
            top: 32px;
            border-top: 8px solid orange;
            border-left: 21px solid transparent;
            border-right: 21px solid transparent;
          }
          i {
            background: orange;
            color: #333;
          }
        }
        &.mm {
          margin-right: -55px;
          &:after {
            top: 32px;
            border-top: 8px solid royalblue;
            border-left: 21px solid transparent;
            border-right: 21px solid transparent;
          }
          i {
            background: royalblue;
            color: #ddf;
          }
        }
        &.blc {
          margin-right: -55px;
          &:after {
            top: 32px;
            border-top: 8px solid #ff0095;
            border-left: 21px solid transparent;
            border-right: 21px solid transparent;
          }
          i {
            background: #ff0095;
            color: #ddf;
          }
        }

        svg,i{
          display: flex;
          background: #d6dade;
          padding: 6px;
          margin: -2px 4px 0px;
          justify-content: center; /* align horizontal */
          align-items: center; /* align vertical */
          color: #333;
          width: 20px;
          color: #333;
        }

        i {
          font-weight: bold;
          width: 30px;
          padding: 8px 6px;
          margin: -2px 4px 0px;
          justify-content: center; /* align horizontal */
          align-items: center; /* align vertical */
          background: #d6dade;
          color: #333;
        }
      }
    }
      // background: rgba(white, 0.5);
      .mm {
        .event-flag-container {
          position: absolute; 
          right: 0px;
          top: -2px;
          height: 60px; 
          width: 0;
          margin-top: -12px;
          border-left: 22px solid darken(royalblue, 2%); 
          border-right: 22px solid royalblue; 
          border-bottom: 22px solid transparent;
        
          h5 {
            padding: 0px 12px;
            font-size: .9em;
            margin-top: -7px;
            &::after {
              content: 'BLC';
            }
          }  
        }
      }
      .pp {
        .event-flag-container {
          position: absolute; 
          right: 7px;
          top: -2px;
          height: 68px; 
          width: 0;
          margin-top: -12px;
          border-left: 12px solid darken(orange, 2%); 
          border-right: 12px solid orange; 
          border-bottom: 12px solid transparent;
          
          h5 {
            padding: 0px 12px;
            font-size: .9em;
            margin-top: -7px;
            &::after {
              content: 'BLC';
            }
          }  
        }
      }
      .blc {
        .event-flag-container {
          position: absolute; 
          right: 4px;
          top: -2px;
          height: 64px; 
          width: 0;
          margin-top: -12px;
          border-left: 18px solid darken(#ff0095, 2%); 
          border-right: 18px solid #ff0095; 
          border-bottom: 18px solid transparent;
        
        h5 {
          padding: 0px 12px;
          font-size: .9em;
          margin-top: -7px;
          &::after {
            content: 'BLC';
          }
        }  
      }
    }

    .event-date, .event-name {
      margin:  2px 0;
    }
    .event-date {
      opacity: .5;
      margin-top: 26px;
    }

    .amigo-head {
      position: relative;
      // height: calc(48px + 12px);
      max-height: calc(48px + 12px);
      margin: 4px 0px 24px 0px;
      img {
        max-height: inherit;
      }
      button {
        max-height: inherit;
      }
    }

    
  }
}