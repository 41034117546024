.amigo-card {
  min-height: 360px;
  background: #eee;
  border-radius: 14px;
  border: #ededed solid 2px;
  padding: 12px;
  margin: 0px 4px;

  &.xs {
    padding: 12px 4px;

    .bottom-anchor {
      width: calc(100% - 30px);
      margin-left: 15px;
    }
  }

  &.cheer-blip {
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
    animation-duration: 0.5s;
    animation-direction: forwards;
  }

  &.cheer-blip-2 {
    -webkit-animation-name: pounce; 
    animation-name: pounce; 
    animation-duration: 0.6s;
    animation-direction: forwards;
  }

  &.place-0 {
    // margin-top: 0px;
    height: 440px;
    background: $goldColor;
    border: darken($goldColor, 4%) solid 2px;
    
    .cheer-container {
      background: darken($goldColor, 4%);
      &:hover {
        background: darken($goldColor, 6%);
      }
    }
  }
  &.place-1 {
    // margin-top: 40px;
    height: 400px;
    background: $silverColor;
    border: darken($silverColor, 4%) solid 2px;
    
    .cheer-container {
      background: darken($silverColor, 4%);
      &:hover {
        background: darken($silverColor, 6%);
      }
    }
  }
  &.place-2 {
    // margin-top: 80px;
    height: 360px;
    background: $bronzeColor;
    border: darken($bronzeColor, 4%) solid 2px;
    
    .cheer-container {
      background: darken($bronzeColor, 4%);
      &:hover {
        background: darken($bronzeColor, 6%);
      }
    }
  }

  .amigo-name {
    text-align: center;
    font-size: 2em;
  }

  .inline-container {
    margin: 20px 0px;
  }

  &.short {
    min-height: unset;
    height: unset;
    margin-bottom: 16px;
    font-size: .7em;

    .card-place {
      font-size: 1.2em;
      margin-top: 0px;
    }

    .card-points {
      margin-bottom: 0px;
      font-weight: bold;
    }
  }

  .bottom-anchor {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin-left: 30px;
    margin-bottom: 13px;
    width: calc(100% - 62px);


    .card-place {
      font-size: 1.4em;
      margin-top: 20px;
    }
    .card-points {
      opacity: .6;
      font-size: 1em;
      margin-bottom: 20px;
      
    }

    .cheer-container {
      position: relative;
      border-radius: 6px;
      width: 100%;
      min-height: 40px;
      text-align: center;
      transition: 200ms;
      cursor: pointer;

      button {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-10px);} 
  60% {transform: translateY(-6px);} 
} 


@keyframes pounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-14px);} 
  60% {transform: translateY(-8px);} 
} 